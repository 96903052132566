import React from 'react';
import Gallery from './Gallery';

const Portfolio = () => {
    return (
        <Gallery />
    )
}

export default Portfolio;
